import { Slot } from '@radix-ui/react-slot';
import type { ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import { type Sprinkles, sprinkles } from '../../sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { joinClassnames } from '../../utilities/internal.js';

/**
 ** Note: any changes should be reflected in companion as well
 */
export interface GridProps
  extends PropsWithChildren<
    Omit<ElementProps<'div'>, 'title'> & RainbowSprinkles
  > {
  css?: RainbowSprinkles & { containerType?: Sprinkles['containerType'] };
  display?: 'grid' | 'inline-grid';
  asChild?: boolean | undefined;
}

/**
 ** Note: any changes should be reflected in companion as well
 */
function Grid(
  { asChild, children, css, display = 'grid', ...props }: GridProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { className, style, otherProps } = rainbowSprinkles({
    ...props,
    ...css,
    display,
  });

  // rainbowSprinkles doesn't support containerType, but sprinkles does, so delete before it gets added to the element
  if (otherProps.containerType) {
    delete otherProps.containerType;
  }
  const Component = asChild ? Slot : 'div';

  return (
    <Component
      className={joinClassnames([
        sprinkles({
          containerType: css?.containerType,
        }),
        className,
      ])}
      ref={ref}
      style={style}
      {...otherProps}
    >
      {children}
    </Component>
  );
}

export const _Grid = forwardRef<HTMLDivElement, GridProps>(Grid);
export { _Grid as Grid };
